



















export default {
  data() {
    return {
      video: 'https://www.youtube.com/embed/4efQRSGBO08?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1'
    };
  },
  methods: {
    download() {
      window.open('https://chrome.google.com/webstore/detail/leakcheckcc-alpha-extensi/fhomcknoglcomgbalioanmgldanahkie');
    },
    t(value: string) {
      return this.$vuetify.lang.t(`$vuetify.ChromeExtension.${value}`);
    }
  }
};
